"use strict";
import { jsx } from "react/jsx-runtime";
import "./style.scss";
import { memo, useMemo } from "react";
import Lottie from "react-lottie";
import { Spinner } from "@patients-ui/icons";
import LogoLoading from "~/../images/animations/logo-loading.json";
export const Loader = memo(() => /* @__PURE__ */ jsx("main", { className: "w-full h-screen bg-primary-900", children: /* @__PURE__ */ jsx("section", { className: "w-full flex h-2 bg-primary animate-pulse" }) }));
export const InitialLoad = memo(() => {
  const defaultOptions = useMemo(() => ({
    loop: true,
    autoplay: true,
    animationData: LogoLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }), []);
  return /* @__PURE__ */ jsx("div", { className: "grid place-items-center w-full h-screen bg-white", children: /* @__PURE__ */ jsx("div", { className: "grid place-items-center w-full", children: /* @__PURE__ */ jsx(
    Lottie,
    {
      options: defaultOptions,
      height: 85,
      width: 85,
      isClickToPauseDisabled: true
    }
  ) }) });
});
InitialLoad.displayName = "InitialLoad";
export const SpinnerLoading = memo(() => /* @__PURE__ */ jsx("div", { className: "spinner_loading", children: /* @__PURE__ */ jsx(Spinner, {}) }));
SpinnerLoading.displayName = "SpinnerLoading";
