"use strict";
export const isDate = (value) => /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/g.test(value);
export const isCpf = (value) => {
  let numbers;
  let digits;
  let sum;
  let i;
  let result;
  let digits_equals = 1;
  value = String(value).replace(/[.|\-]/g, "");
  if (value.length < 11) {
    return false;
  }
  for (i = 0; i < value.length - 1; i += 1) {
    if (value.charAt(i) !== value.charAt(i + 1)) {
      digits_equals = 0;
      break;
    }
  }
  if (!digits_equals) {
    numbers = value.substring(0, 9);
    digits = value.substring(9);
    sum = 0;
    for (i = 10; i > 1; i -= 1) {
      sum += numbers.charAt(10 - i) * i;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (parseInt(result, 10) !== parseInt(digits.charAt(0), 10)) {
      return false;
    }
    numbers = value.substring(0, 10);
    sum = 0;
    for (i = 11; i > 1; i -= 1) {
      sum += numbers.charAt(11 - i) * i;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (parseInt(result, 10) !== parseInt(digits.charAt(1), 10)) {
      return false;
    }
    return true;
  }
  return false;
};
export const isEmail = (value) => /^([\w\-]+(?:\.[\w\-]+)*)@((?:[\w\-]+\.)*\w[\w\-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(value);
export const isNumber = (value) => /\d+/g.test(String(value));
export const isVaccine = (value) => value.name.toLocaleLowerCase().includes("vacina");
export const isExam = (value) => value.related_type === "Exames";
export const isSUS = (value) => value.type_exam_selected === "sus";
export const isTUSS = (value) => value.type_exam_selected === "tuss";
export const isWhiteLabel = (value) => !["MedicamentoCustom", "Exames", "Manipulados"].includes(value.related_type);
