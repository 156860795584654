"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Typography, Button } from "@patients-ui/components";
import { removeSessionItem } from "~/utils/storage";
import { useModalsContext } from "../../../ModalsContext";
const AlertModalContent = () => {
  const { toggleAlert, toggleAuth } = useModalsContext();
  const closeAllModals = () => {
    removeSessionItem("privacy_form");
    toggleAlert();
    toggleAuth();
  };
  return /* @__PURE__ */ jsxs("div", { className: "p-6", children: [
    /* @__PURE__ */ jsxs("div", { className: "flex flex-col gap-4", children: [
      /* @__PURE__ */ jsx(Typography, { as: "h1", variant: "subtitle", spacing: "distant", weight: "500", children: "Autentica\xE7\xE3o incompleta" }),
      /* @__PURE__ */ jsx(Typography, { variant: "label", spacing: "distant", weight: "400", children: "Voc\xEA n\xE3o completou a sua autentica\xE7\xE3o, desse modo n\xE3o poderemos salvar as op\xE7\xF5es para servi\xE7os dispon\xEDveis na sua receita digital" })
    ] }),
    /* @__PURE__ */ jsxs("div", { className: "flex flex-row gap-4 mt-4", children: [
      /* @__PURE__ */ jsx(Button, { variant: "ghost", title: "Cancelar", size: "sm", onClick: closeAllModals, children: "Cancelar" }),
      /* @__PURE__ */ jsx(Button, { title: "Autenticar", type: "submit", size: "sm", onClick: toggleAlert, children: "Autenticar" })
    ] })
  ] });
};
export default AlertModalContent;
