"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import axios from "axios";
export class Service {
  constructor(baseURL, config) {
    __publicField(this, "instance");
    __publicField(this, "controller");
    this.controller = new AbortController();
    this.instance = axios.create(__spreadProps(__spreadValues({}, config), {
      baseURL,
      signal: this.controller.signal
    }));
    this.instance.interceptors.request.use((config2) => {
      this.controller = new AbortController();
      return __spreadProps(__spreadValues({}, config2), {
        signal: this.controller.signal
      });
    });
  }
  /**
   * Aborta todas as requisiçoes relacionadas ao uso desse objeto
   */
  abort() {
    this.controller.abort();
  }
}
