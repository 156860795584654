"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Outlet } from "react-router-dom";
import { NavBar } from "~/views/partials/Navbar";
import { ModalsProvider } from "~/views/contexts/ModalsContext";
const WithNavbar = () => /* @__PURE__ */ jsx(ModalsProvider, { children: /* @__PURE__ */ jsxs("div", { className: "flex flex-col min-h-screen", children: [
  /* @__PURE__ */ jsx("div", { className: "flex flex-col flex-grow", children: /* @__PURE__ */ jsx(Outlet, {}) }),
  /* @__PURE__ */ jsx(NavBar, {})
] }) });
export default WithNavbar;
