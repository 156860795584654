"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
class XRay {
  constructor() {
    __publicField(this, "app");
    __publicField(this, "environmentMode");
    __publicField(this, "tracker");
    __publicField(this, "experiments");
    this.app = {
      version: "1.0.0",
      name: "Assistant",
      build: "0.0.0",
      namespace: "assistant.memed"
    };
    this.environmentMode = process.env.APP_ENV === "local" ? "DEBUG" : "LIVE";
    this.tracker = new window.XRayTracker(process.env.X_RAY_TRACKER_ENV);
    this.tracker.setMode(this.environmentMode);
    this.experiments = [];
  }
  init(properties) {
    return __async(this, null, function* () {
      try {
        yield this.tracker.load(this.app, properties);
      } catch (error) {
        console.error("N\xE3o foi poss\xEDvel carregar o X-RayTracker SDK.", error);
      }
    });
  }
  sendEvent({ name, screen, properties }) {
    try {
      this.tracker.event(name, screen, properties);
    } catch (error) {
      console.error(`N\xE3o foi poss\xEDvel enviar o evento ${name}.`, error);
    }
  }
  identify(userId) {
    return __async(this, null, function* () {
      try {
        yield this.tracker.identify({ id: userId, userType: "patient" });
        const experiments = yield this.tracker.getExperiments();
        this.experiments = experiments;
      } catch (error) {
        console.error("N\xE3o foi poss\xEDvel se autenticar ao X-RayTracker SDK.", error);
      }
    });
  }
  resetIdentity() {
    return __async(this, null, function* () {
      try {
        yield this.tracker.resetIdentity();
      } catch (error) {
        console.error("N\xE3o foi poss\xEDvel se deslogar do X-RayTracker SDK.", error);
      }
    });
  }
  persistData(key, value) {
    try {
      this.tracker.persistData(key, value);
    } catch (error) {
      console.error(`N\xE3o foi poss\xEDvel persistir o atributo ${key}, X-RayTracker SDK n\xE3o foi encontrado.`, error);
    }
  }
  deletePersistentData(key) {
    try {
      this.tracker.persistData(key, null);
    } catch (error) {
      console.error(`N\xE3o foi poss\xEDvel remover o atributo ${key}, X-RayTracker SDK n\xE3o foi encontrado.`, error);
    }
  }
  getExperiment(experimentName) {
    const experiment = this.experiments.find(({ name }) => name === experimentName);
    if (experiment) {
      return experiment;
    }
    return {};
  }
  createUrlParam(properties = {}) {
    try {
      return this.tracker.createUrlParam(properties);
    } catch (error) {
      console.error("N\xE3o foi poss\xEDvel criar os parametros da url, X-RayTracker SDK n\xE3o foi encontrado.", error);
      return null;
    }
  }
}
export const xRay = new XRay();
