"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useReducer } from "react";
const initialState = {};
const reducer = (prevState, action) => {
  switch (action.type) {
    case "SELECT_EXAMS": {
      const { domiciliary, presential } = action.payload;
      return __spreadProps(__spreadValues({}, prevState), {
        exams: {
          domiciliary: {
            exists: domiciliary.length > 0,
            groupId: domiciliary.length > 0 ? domiciliary[0].group_id : 0,
            data: domiciliary
          },
          presential: {
            exists: presential.length > 0,
            groupId: presential.length > 0 ? presential[0].group_id : 0,
            data: presential
          },
          total: domiciliary.length + presential.length
        }
      });
    }
    case "SELECT_SERVICE_TYPE": {
      return __spreadProps(__spreadValues({}, prevState), {
        serviceType: action.payload,
        selectedExams: prevState.exams[action.payload].data,
        selectedGroupId: prevState.exams[action.payload].groupId
      });
    }
    case "SELECT_LAB": {
      return __spreadProps(__spreadValues({}, prevState), {
        selectedLab: action.payload
      });
    }
    case "SET_ADDRESS": {
      return __spreadProps(__spreadValues({}, prevState), {
        address: action.payload
      });
    }
    case "SET_DATE": {
      return __spreadProps(__spreadValues({}, prevState), {
        selectedDate: action.payload.selectedDate,
        selectedTime: action.payload.selectedTime,
        periodOfDay: action.payload.periodOfDay
      });
    }
    default: {
      return prevState;
    }
  }
};
export const useSchedulingReducer = (persistentState) => useReducer(reducer, __spreadValues(__spreadValues({}, initialState), persistentState));
