"use strict";
export const getCookie = (cookieName, fallback) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";").map((cookie2) => cookie2.trim());
  const cookie = cookies.find((cookie2) => cookie2.startsWith(`${cookieName}=`));
  if (cookie) {
    try {
      return JSON.parse(cookie.substring(cookieName.length + 1));
    } catch (err) {
      return cookie.substring(cookieName.length + 1);
    }
  }
  return fallback || null;
};
const setCookie = (cookieName, value, expiryDays = 1) => {
  const expires = /* @__PURE__ */ new Date();
  expires.setTime(expires.getTime() + expiryDays * 24 * 60 * 60 * 1e3);
  document.cookie = `${cookieName}=${JSON.stringify(value)};expires=${expires.toUTCString()}`;
};
const checkACookieExists = (cookieName) => {
  const cookieExists = document.cookie.split(";").some((item) => item.trim().startsWith(`${cookieName}=`));
  return cookieExists;
};
export const setCookieIfNotExist = (cookieName, value, expiryDays) => {
  const cookieExists = checkACookieExists(cookieName);
  if (!cookieExists) {
    setCookie(cookieName, value, expiryDays);
  }
};
