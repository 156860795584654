"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useForm } from "react-hook-form";
import { Button, Input } from "@patients-ui/components";
import { useAddressContext } from "../..";
import { xRay } from "~/utils/tracking";
const PostalCode = () => {
  const { address, toggleAddressModal, getAddress } = useAddressContext();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    mode: "onSubmit"
  });
  const onSubmit = (body) => __async(void 0, null, function* () {
    var _a, _b;
    xRay.sendEvent({
      name: "Direct Sale Drugs Listing Address Searching Click",
      screen: "direct_sale_drugs_listing"
    });
    const result = yield getAddress(body.postalcode);
    if (!((_a = result.city) == null ? void 0 : _a.length) && !((_b = result.state) == null ? void 0 : _b.length)) {
      setError("postalcode", {
        message: "O CEP digitado n\xE3o \xE9 valido."
      });
      return;
    }
    toggleAddressModal();
  });
  return /* @__PURE__ */ jsx("div", { className: "w-full mt-4", children: /* @__PURE__ */ jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
      Input,
      __spreadValues({
        inputMode: "numeric",
        type: "tel",
        label: "Informe seu CEP",
        error: errors.postalcode && errors.postalcode.message ? errors.postalcode.message.toString() : false,
        defaultValue: address.postalCode,
        placeholder: "00000-000",
        disabled: isSubmitting,
        mask: "00000-000"
      }, register("postalcode", {
        required: "Digite um CEP para buscar"
      }))
    ) }),
    /* @__PURE__ */ jsx("a", { href: process.env.CORREIOS_URL, target: "_blank", title: "N\xE3o sei meu CEP", rel: "noreferrer", className: "underline text-primary-600", children: "N\xE3o sei meu CEP" }),
    /* @__PURE__ */ jsx(Button, { title: "Buscar", type: "submit", disabled: !isDirty, isLoading: isSubmitting, children: "Buscar" })
  ] }) });
};
export default PostalCode;
