"use strict";
import { Service } from "../service";
const PERMISSION_URL = "qa-drugs.memed.com.br";
class ExamsApi extends Service {
  constructor() {
    super(process.env.API_EXAMS, {
      headers: {
        permission: PERMISSION_URL
      }
    });
  }
  getExclusiveLabsV2(payload) {
    return this.instance.post("/v2/laboratories/exclusive", payload);
  }
  getExclusiveLabs(payload) {
    return this.instance.get(`/laboratories/exclusive/uf/${payload.address.state}?city=${payload.address.city}`, {
      headers: {
        city: payload.address.city,
        partnerid: payload.partnerId
      }
    });
  }
  getLabsAvailability(payload) {
    return this.instance.post("/v2/available/", payload);
  }
  getTranslatedLabs(payload) {
    return this.instance.post("/translate/labs/ids-memed", payload);
  }
  getPartnerIntegrationStatus() {
    return this.instance.get("/v1/integration/status/partner");
  }
  getLabCoverage(payload) {
    return this.instance.get(`/v1/integration/partner/${payload.groupId}/scheduling-coverage/${payload.postalCode}`);
  }
  getAvailableLabs(payload) {
    return this.instance.get(`/laboratories/available/uf/${payload.state}/city/${payload.city}/`);
  }
  getAvailableScheduling(payload) {
    return this.instance.get(`/v1/integration/partner/${payload.groupId}/scheduling-available/${payload.selectedDate}?zipcode=${payload.postalCode}`);
  }
  getSchedulingConfirmation(payload) {
    return this.instance.post(`/v1/integration/partner/${payload.partnerId}/scheduling`, payload.data);
  }
  getDocument(payload) {
    return this.instance.post("/scheduling/create/pdf", payload, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
        "Content-Type": "application/json"
      }
    });
  }
}
export const examsApi = new ExamsApi();
