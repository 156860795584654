"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import PostalCode from "./partials/PostalCode";
import useModal from "~/hooks/useModal";
import { getLocalItem, setLocalItem, removeLocalItem } from "~/utils/storage";
import { usePrescriptionContext } from "../PrescriptionContext";
import { vtexApi } from "~/services/vtexApi";
const AddressContext = createContext({});
export const AddressProvider = ({ children }) => {
  const [Modal, { toggle, isOpen }] = useModal();
  const [address, setAddress] = useState(() => getLocalItem("addressComponents", {}));
  const { isPreviewMode } = usePrescriptionContext();
  const clearAddress = useCallback(() => removeLocalItem("addressComponents"), []);
  const setAddressData = useCallback((data) => {
    if (data.city.length && data.state.length) {
      setLocalItem("addressComponents", JSON.stringify(data));
      setAddress(data);
      return;
    }
    clearAddress();
  }, [clearAddress]);
  const getAddress = useCallback((postalcode) => __async(void 0, null, function* () {
    try {
      if (!postalcode) {
        return {};
      }
      const { data } = yield vtexApi.getAddressByPostalCode(postalcode);
      setAddressData(data);
      return data;
    } catch (err) {
      console.error(`Erro ao buscar cep: ${err}`);
    }
    return {};
  }), [setAddressData]);
  const getAddressByGeo = useCallback(() => new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition((position) => __async(void 0, null, function* () {
      const { latitude, longitude } = position.coords;
      try {
        const { data } = yield vtexApi.getAddressByGeolocation({
          latitude,
          longitude
        });
        setAddressData(data);
        resolve(data);
      } catch (error) {
        console.error(`Erro ao carregar geolocaliza\xE7\xE3o: ${error}`);
      }
    }), () => resolve({}));
  }), [setAddressData]);
  const verifyToggleModal = useCallback(() => __async(void 0, null, function* () {
    if (!address.postalCode) {
      yield getAddressByGeo();
    }
  }), [address.postalCode, getAddressByGeo]);
  const toggleAddressModal = useCallback(() => {
    toggle();
  }, [toggle]);
  const value = useMemo(() => ({
    address,
    getAddress,
    toggleAddressModal
  }), [address, getAddress, toggleAddressModal]);
  useEffect(() => {
    if (!isPreviewMode) {
      verifyToggleModal();
    }
  }, [verifyToggleModal, isPreviewMode]);
  return /* @__PURE__ */ jsxs(AddressContext.Provider, { value, children: [
    children,
    /* @__PURE__ */ jsx(Modal, { direction: "bottom", title: "Encontre locais pr\xF3ximos", isOpen, children: /* @__PURE__ */ jsx(PostalCode, {}) })
  ] });
};
export const useAddressContext = () => useContext(AddressContext);
