"use strict";
import { jsx } from "react/jsx-runtime";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from "react";
import { Outlet } from "react-router-dom";
import { getSessionItem, setSessionItem, removeSessionItem } from "~/utils/storage";
import { useSchedulingReducer } from "./reducer";
const SESSION_KEY = "exams_form_data";
const SchedulingContext = createContext({});
export const SchedulingProvider = () => {
  const formInitialState = getSessionItem(SESSION_KEY) ? getSessionItem(SESSION_KEY) : {};
  const [formData, dispatch] = useSchedulingReducer(formInitialState);
  const clear = useCallback(() => removeSessionItem(SESSION_KEY), []);
  useEffect(() => {
    setSessionItem(SESSION_KEY, JSON.stringify(formData));
  }, [formData]);
  const contextValue = useMemo(() => ({
    formData,
    dispatch,
    clear
  }), [formData, dispatch, clear]);
  return /* @__PURE__ */ jsx(SchedulingContext.Provider, { value: contextValue, children: /* @__PURE__ */ jsx(Outlet, {}) });
};
export const useSchedulingContext = () => {
  const context = useContext(SchedulingContext);
  if (!context) {
    throw new Error("useSchedulingContext must be used in a SchedulingProvider!");
  }
  return context;
};
