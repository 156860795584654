"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback } from "react";
import { ArrowLeft } from "@patients-ui/icons";
import { Link } from "react-router-dom";
import { Typography } from "@patients-ui/components";
export const SimpleHeader = forwardRef((_a, ref) => {
  var _b = _a, {
    children,
    label,
    path = "",
    handleClick,
    fixed,
    className
  } = _b, rest = __objRest(_b, [
    "children",
    "label",
    "path",
    "handleClick",
    "fixed",
    "className"
  ]);
  const onClick = useCallback((e) => {
    if (!path && handleClick) {
      handleClick(e);
    }
  }, [handleClick, path]);
  return /* @__PURE__ */ jsx("header", __spreadProps(__spreadValues({ ref, className: `py-2 px-4 ${fixed ? "sticky top-0 bg-white z-10" : ""} ${className}` }, rest), { "aria-hidden": "false", children: /* @__PURE__ */ jsxs("div", { className: "flex items-center justify-center relative h-10", children: [
    /* @__PURE__ */ jsx(Link, { to: path, onClick, className: "flex items-center justify-center absolute left-0", "aria-label": "Voltar", children: /* @__PURE__ */ jsx(ArrowLeft, {}) }),
    label ? /* @__PURE__ */ jsx(Typography, { as: "h1", className: "text-xl text-neutral-700 leading-shorter tracking-tight font-medium mx-auto", children: label }) : null,
    /* @__PURE__ */ jsx("div", { children })
  ] }) }));
});
SimpleHeader.displayName = "SimpleHeader";
