"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import styles from "./styles.modules.scss";
const Avatar = ({ name }) => {
  const initials = useMemo(() => {
    const splitName = name.split(" ");
    let letters = splitName[0].charAt(0).toUpperCase();
    if (splitName.length > 1) {
      letters += splitName[1].charAt(0).toUpperCase();
    }
    return letters;
  }, [name]);
  return /* @__PURE__ */ jsx("div", { className: `${styles.Avatar}`, children: /* @__PURE__ */ jsx("span", { children: initials }) });
};
export default Avatar;
