"use strict";
import { Service } from "../service";
class AssistantApi extends Service {
  constructor() {
    super(process.env.ASSISTANT_API);
  }
  /**
   * Busca os dados do paciente por ID
   * @param {string} id - ID da paciente
   * @returns {Promise}
   */
  getPatientById(id) {
    return this.instance.get(`/v1/privacy/documents/tu/patient/${id}`);
  }
  /**
   * Atualiza os dados do paciente
   * @param {Object} payload
   * @param {string} payload.id - ID do paciente
   * @param {string} payload.token - Token da receita
   * @param {string} payload.code - Codigo de desbloqueio
   * @param {IPrivacyItem[]} payload.itens - Items de configuração da privacidade
   * @returns {Promise}
   */
  updatePatient({
    id,
    token,
    code,
    itens
  }) {
    return this.instance.post(`/v1/privacy/documents/tu/patient/${id}`, {
      prescription: {
        prescription_token: token,
        code
      },
      itens
    });
  }
}
export const assistantApi = new AssistantApi();
