"use strict";
import { Service } from "../service";
class VtexApi extends Service {
  constructor() {
    super(process.env.API_ROOT);
  }
  /**
   * Busca os dados do seller por ID
   * @param {string} id - ID da seller
   * @returns {Promise}
   */
  getSellerById(id) {
    return this.instance.get(`sellers/${id}`);
  }
  /**
   * Busca os dados de localização do paciente por cep
   * @param {string} postalcode - CEP do paciente
   * @returns {Promise}
   */
  getAddressByPostalCode(postalcode) {
    return this.instance.get(`/postal_code/${postalcode}/`);
  }
  /**
   * Busca os dados de localização do paciente por geolocalização
   * @param {IGeoLocationParams} params
   * @returns {Promise}
   */
  getAddressByGeolocation(params) {
    return this.instance.get("/geocode", {
      params
    });
  }
  /**
   * Simula um carrinho de compras com segmentação por cada seller
   * @param {RequestSellersPayload} payload
   * @returns {Promise}
   */
  getSimulate(payload) {
    return this.instance.post("/cart/simulate/", payload);
  }
  /**
   * Simula um carrinho de compras com segmentação por cada seller com items intercambiaveis
   * @param {RequestSellersPayload} payload
   * @returns {Promise}
   */
  getBestPriceSimulate(payload) {
    return this.instance.post("/cart/simulate/best-prices/", payload);
  }
  /**
   * Simula um carrinho de compras com segmentação por cada seller com items intercambiaveis
   * @param {IItemsBestPricePayload} payload
   * @returns {Promise}
   */
  getItemsBestPrice(payload) {
    return this.instance.post("/prescription_items_best_prices", payload);
  }
  /**
   * Gera um Order Form Id para a vtex
   * @param {RequestOrderFormIdPayload} payload
   * @returns {Promise}
   */
  getOrderFormId(payload) {
    return this.instance.post("/cart", payload);
  }
  /**
   * Busca as avaliações do marketplace
   * @returns {Promise}
   */
  getReviews() {
    return this.instance.get("/reviews/order");
  }
  /**
   * Busca um order form existente
   * @param {string} orderFormId
   * @returns {Promise}
   */
  getOrderForm(orderFormId) {
    return this.instance.get(`/cart/order_form/${orderFormId}`);
  }
  /**
   * Busca as avaliações do marketplace
   * @param {ICheckoutPayload} payload
   * @returns {Promise}
   */
  applyDiscount(payload) {
    return this.instance.post("/cart/apply-discount", payload);
  }
}
export const vtexApi = new VtexApi();
