"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useReducer } from "react";
const initialState = {
  prescription: {},
  patient: {},
  isInitialLoading: true,
  hasError: false,
  status: null,
  prescriptionError: {
    exists: false,
    status: 0
  }
};
const reducer = (oldState, { type, payload }) => {
  switch (type) {
    case "COMPLETE_PRESCRIPTION":
      return __spreadProps(__spreadValues({}, oldState), {
        prescription: payload.prescription,
        patient: payload.patient,
        isInitialLoading: false,
        hasError: false
      });
    case "UPDATE_PRIVACY":
      return __spreadProps(__spreadValues({}, oldState), {
        patient: payload.patient,
        hasError: false
      });
    case "UPDATE_PRESCRIPTION":
      return __spreadProps(__spreadValues({}, oldState), {
        prescription: __spreadProps(__spreadValues({}, oldState.prescription), {
          digits: payload.prescription.digits,
          isSigned: payload.prescription.isSigned,
          document: payload.prescription.document,
          doctor: payload.prescription.doctor,
          patient: payload.prescription.patient
        }),
        hasError: false
      });
    case "ERROR_PRESCRIPTION":
      return __spreadProps(__spreadValues({}, oldState), {
        prescriptionError: payload.prescriptionError
      });
    case "ERROR_PRIVACY":
      return __spreadProps(__spreadValues({}, oldState), {
        isInitialLoading: false,
        hasError: true
      });
    case "CLEAR_ERRORS":
      return __spreadProps(__spreadValues({}, oldState), {
        isInitialLoading: false,
        hasError: false
      });
    default:
      return oldState;
  }
};
export default () => useReducer(reducer, initialState);
