"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { Typography } from "@patients-ui/components";
import styles from "./styles.module.scss";
const FeedbackModalContent = () => {
  const { token } = useParams();
  return /* @__PURE__ */ jsxs("div", { className: styles.token_modal_container, children: [
    /* @__PURE__ */ jsx("div", { className: styles.token_modal_clipboard, children: /* @__PURE__ */ jsxs(Typography, { variant: "subtitle", spacing: "tight", weight: "500", children: [
      "Seu c\xF3digo: ",
      token
    ] }) }),
    /* @__PURE__ */ jsxs("div", { className: styles.token_modal_about, children: [
      /* @__PURE__ */ jsx(Typography, { as: "h1", variant: "subtitle", spacing: "tight", weight: "500", children: "O que \xE9 o c\xF3digo da receita?" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", spacing: "distant", weight: "400", children: "O c\xF3digo da sua receita serve para que a loja consiga identific\xE1-la e possibilita que receitas de medicamentos controlados possam ser retidas de forma on-line. Para realizar a compra de forma presencial na loja voc\xEA pode informar o c\xF3digo da receita ou mostrar o QR Code que est\xE1 na parte inferior da sua receita." })
    ] })
  ] });
};
export default FeedbackModalContent;
