"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { Signature, Error } from "@patients-ui/icons";
import { Typography } from "@patients-ui/components";
import { useRoute } from "@memed/react-router-mapping";
import { usePrescriptionContext } from "../../../PrescriptionContext";
import Avatar from "~/views/partials/Avatar";
import { isoToDate } from "~/utils/formatters";
import { useModalContext } from "~/hooks/useModal";
const DetailsModalContent = () => {
  const {
    prescription: {
      doctor,
      expiration_date,
      isSigned,
      prescription_date,
      patient
    }
  } = usePrescriptionContext();
  const { route } = useRoute();
  const navigate = useNavigate();
  const { toggle } = useModalContext();
  const redirectToPage = () => {
    navigate(route("signature"));
    toggle();
  };
  const prescriptionDate = prescription_date && prescription_date.length ? isoToDate(prescription_date) : "Data n\xE3o informada";
  return /* @__PURE__ */ jsxs("div", { className: "mt-4", children: [
    /* @__PURE__ */ jsxs("div", { className: "flex items-center gap-4", children: [
      /* @__PURE__ */ jsx(Avatar, { name: doctor.name || "" }),
      /* @__PURE__ */ jsxs("div", { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", spacing: "tight", weight: "500", children: doctor.name || "" }),
        doctor.regional_code ? /* @__PURE__ */ jsxs(Typography, { variant: "label", spacing: "distant", weight: "400", children: [
          doctor.regional_code,
          " ",
          doctor.board_number,
          "-",
          doctor.uf
        ] }) : null
      ] })
    ] }),
    /* @__PURE__ */ jsxs("div", { className: "mt-4", children: [
      /* @__PURE__ */ jsx("div", { className: "py-4 border-b border-neutral-200", children: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", spacing: "distant", weight: "400", "aria-label": `Data da receita: ${prescriptionDate}`, children: [
        "Data da receita:",
        " ",
        /* @__PURE__ */ jsx("strong", { children: prescriptionDate })
      ] }) }),
      expiration_date ? /* @__PURE__ */ jsx("div", { className: "py-4 border-b border-neutral-200", children: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", spacing: "distant", weight: "400", "aria-label": `Data de validade: ${isoToDate(expiration_date)}`, children: [
        "Data de validade:",
        " ",
        /* @__PURE__ */ jsx("strong", { children: isoToDate(expiration_date) })
      ] }) }) : null,
      /* @__PURE__ */ jsx("div", { className: "py-4", children: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", spacing: "distant", weight: "400", "aria-label": `Paciente: ${patient.name}`, children: [
        "Paciente:",
        " ",
        /* @__PURE__ */ jsx("strong", { children: patient.name })
      ] }) })
    ] }),
    /* @__PURE__ */ jsxs("div", { className: "mt-4", children: [
      /* @__PURE__ */ jsxs("div", { className: `flex gap-2 items-center p-4 rounded-sm border ${isSigned ? "border-success-500 bg-success-50" : "border-danger-500 bg-danger-50"}`, children: [
        isSigned ? /* @__PURE__ */ jsx(Signature, { className: "text-success-700" }) : /* @__PURE__ */ jsx(Error, { className: "text-danger-700" }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", spacing: "distant", weight: "400", className: `${isSigned ? "text-success-700" : "text-danger-800"}`, children: isSigned ? "Receita assinada digitalmente" : "Receita n\xE3o assinada" })
      ] }),
      /* @__PURE__ */ jsx("button", { title: "Saiba o que isso significa", className: "block font-normal text-base text-primary-600 underline mt-2", onClick: redirectToPage, children: "Saiba o que isso significa" })
    ] })
  ] });
};
export default DetailsModalContent;
