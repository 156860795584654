"use strict";
import { jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom";
import "../scss/index.scss";
import Routes from "./Routes";
ReactDOM.render(
  /* @__PURE__ */ jsx(React.StrictMode, { children: /* @__PURE__ */ jsx(Routes, {}) }),
  document.querySelector("#root")
);
