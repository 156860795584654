"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { matchPath } from "react-router-dom";
import { Typography } from "@patients-ui/components";
import { Close, Question } from "@patients-ui/icons";
import styles from "./styles.module.scss";
import { usePrescriptionContext } from "~/views/contexts/PrescriptionContext";
import { xRay } from "~/utils/tracking";
import { UNLOCK_HELPER_LINK } from "~/utils/helpers";
const Alert = () => {
  const [showAlert, setShowAlert] = useState(true);
  const { prescription, hasError, clearErrors } = usePrescriptionContext();
  const isSentViaQrCode = useMemo(() => prescription.sent_via === "qrcode", [prescription.sent_via]);
  const closeAlert = useCallback(() => {
    setShowAlert(false);
    clearErrors();
  }, [clearErrors]);
  useEffect(() => {
    if (hasError) {
      const isPrescriptionRoute = matchPath({ path: "/p/:token/receita" }, window.location.pathname);
      xRay.sendEvent({
        name: "Download PDF Submit Error Message",
        screen: isPrescriptionRoute ? "assistant_home" : "direct_sale_drugs_listing",
        properties: {
          is_unlock_by_phone: true
        }
      });
    }
    return () => closeAlert();
  }, [hasError, closeAlert]);
  return showAlert ? /* @__PURE__ */ jsxs("div", { className: "py-4", children: [
    /* @__PURE__ */ jsx("div", { className: "pb-4", children: /* @__PURE__ */ jsx(Typography, { variant: "detail", spacing: "distant", weight: "400", children: isSentViaQrCode ? "D\xEDgitos inv\xE1lidos." : "D\xEDgitos inv\xE1lidos. Verifique se digitou os 4 \xFAltimos d\xEDgitos do celular em que recebeu o SMS ou WhatsApp." }) }),
    /* @__PURE__ */ jsxs("div", { className: styles.alert_container, children: [
      /* @__PURE__ */ jsx("div", { className: styles.alert_icon_container, children: /* @__PURE__ */ jsx(Question, {}) }),
      /* @__PURE__ */ jsxs("div", { className: styles.alert_content, children: [
        /* @__PURE__ */ jsxs("div", { className: styles.alert_header, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "label", spacing: "distant", weight: "400", children: "Precisa de ajuda para desbloquear sua receita?" }),
          /* @__PURE__ */ jsx("button", { onClick: closeAlert, children: /* @__PURE__ */ jsx(Close, {}) })
        ] }),
        /* @__PURE__ */ jsx("a", { className: styles.alert_link, href: UNLOCK_HELPER_LINK, target: "_blank", rel: "noreferrer", children: "Acesse nossa ajuda" })
      ] })
    ] })
  ] }) : null;
};
export default Alert;
