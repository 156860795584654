"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const parseStorage = (values) => {
  const result = {};
  Object.keys(values).forEach((key) => {
    try {
      result[key] = JSON.parse(values[key]);
    } catch (err) {
      result[key] = values[key];
    }
  });
  return result;
};
export const setLocalItem = (key, value) => localStorage.setItem(key, value);
export const getLocalItem = (key, defaultValue = null) => {
  const assignLocalStorage = __spreadValues({}, localStorage);
  return parseStorage(assignLocalStorage)[key] || defaultValue;
};
export const setSessionItem = (key, value) => sessionStorage.setItem(key, value);
export const getSessionItem = (key, defaultValue = null) => {
  const assignSessionStorage = __spreadValues({}, sessionStorage);
  return parseStorage(assignSessionStorage)[key] || defaultValue;
};
export const removeLocalItem = (key) => {
  localStorage.removeItem(key);
};
export const removeSessionItem = (key) => {
  sessionStorage.removeItem(key);
};
