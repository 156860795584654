"use strict";
import { detect } from "detect-browser";
import { getCookie } from "./cookies";
import { xRay } from "./tracking";
export const forceDownloadPdf = (pdf, url) => {
  const browser = detect();
  if (browser) {
    if (["fxios", "ios", "chrome", "safari", "crios"].includes(browser.name)) {
      window.location.href = url;
      return;
    }
  }
  window.open(pdf, "_blank");
};
export const openChat = () => {
  xRay.sendEvent({
    name: "Patient Help Click",
    screen: "assistant_home"
  });
  if (window.botpressWebChat) {
    window.botpressWebChat.sendEvent({
      type: "show"
    });
  }
};
export const superDrugstores = process.env.SUPER_DRUGSTORES ? JSON.parse(process.env.SUPER_DRUGSTORES) : [];
export const isDatadogBot = window._DATADOG_SYNTHETICS_BROWSER || getCookie("datadog-synthetics-public-id") || getCookie("datadog-synthetics-result-id");
export const UNLOCK_HELPER_LINK = "https://suporte-paciente.memed.com.br/hc/pt-br/articles/5358238868891-Como-desbloquear-os-dados-protegidos-da-minha-receita-exame-Memed";
