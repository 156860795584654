"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import {
  useCallback,
  useState,
  createContext,
  useContext,
  useMemo
} from "react";
import { Modal } from "@patients-ui/components";
const ModalContext = createContext({});
export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalContext can only be used inside a ModalProvider!");
  }
  return context;
};
const useModal = (defaultIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const onClose = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((old) => !old), []);
  const value = useMemo(() => ({ toggle }), [toggle]);
  const Container = useCallback((_a) => {
    var _b = _a, { children, isOpen: isOpen2 } = _b, rest = __objRest(_b, ["children", "isOpen"]);
    return /* @__PURE__ */ jsx(Modal, __spreadProps(__spreadValues({}, rest), { isOpen: isOpen2, onClose, children: /* @__PURE__ */ jsx(ModalContext.Provider, { value, children }) }));
  }, [value, onClose]);
  return [Container, __spreadProps(__spreadValues({}, value), { isOpen })];
};
export default useModal;
