"use strict";
import { isDatadogBot } from "~/utils/helpers";
import { Service } from "../service";
class PrescriptionApi extends Service {
  constructor() {
    super(process.env.API_PRESCRIPTION);
  }
  /**
   * Busca a prescrição bloqueada somente com o "token" ou desbloqueada passando parametros adicionais
   * @param {IPrescriptionPayload} payload
   * @returns {Promise}
   */
  getPrescription(payload) {
    return this.instance.post("/v1/patients/prescriptions/full", payload, {
      headers: {
        "x-token": String(payload.token),
        Authorization: isDatadogBot ? "Basic bm9yZXBseUBtZW1lZC5jb20uYnI6bWVtZWQxMjM=" : ""
      }
    });
  }
}
export const prescriptionApi = new PrescriptionApi();
