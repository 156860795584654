"use strict";
import { parse } from "date-fns";
export const formatCurrency = (value) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(value / 100);
};
export const normalizeDirectSaleItemsJson = (sellers) => sellers.map(({
  id,
  name,
  products,
  cart: { shippingOptions }
}) => {
  var _a, _b, _c;
  return {
    seller_id: id,
    seller_name: name,
    number_of_items: products.length,
    items_listing: products.map(({
      availability,
      hasComplement,
      ean,
      id: productId,
      price,
      sellingPrice
    }) => ({
      available: availability === "available",
      interchangeble: hasComplement,
      ean,
      sku: productId.toString(),
      product_price: price,
      product_selling_price: sellingPrice
    })),
    order_id: "",
    shipping_estimative: Number(shippingOptions.length > 0 ? (_b = (_a = shippingOptions[0]) == null ? void 0 : _a.transitTime) == null ? void 0 : _b.replace("h", "") : 0),
    shipping_price: shippingOptions.length > 0 ? (_c = shippingOptions[0]) == null ? void 0 : _c.price : 0,
    shipping_listing: shippingOptions.length > 0 ? shippingOptions.map(({ transitTime, price }) => ({
      estimative: Number(transitTime == null ? void 0 : transitTime.replace("h", "")),
      price
    })) : []
  };
});
export const deliveryTimeOptions = (time) => {
  if (time.includes("hora") || parseInt(time.split(" ")[0], 10) <= 1) {
    return true;
  }
  return false;
};
export const jsonToBase64Encode = (properties) => window.btoa(JSON.stringify(properties));
export const dateToIso = (value) => {
  try {
    return parse(value, "dd/MM/yyyy", /* @__PURE__ */ new Date()).toISOString();
  } catch (e) {
    return null;
  }
};
export const isoToDate = (value) => {
  try {
    return parse(value, "yyyy-MM-ddd", /* @__PURE__ */ new Date()).toLocaleDateString();
  } catch (e) {
    return null;
  }
};
export const slugfy = (str) => {
  str = str.trim();
  str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 -]/g, "");
  str = str.replace(/\s+/g, "-");
  str = str.toLowerCase();
  return str;
};
