"use strict";
import { UnleashClient } from "unleash-proxy-client";
const SECONDS_IN_A_DAY = 24 * 60 * 60;
const unleashConfig = {
  url: process.env.REACT_APP_UNLEASH_URL,
  clientKey: process.env.REACT_APP_UNLEASH_PROXY_KEY,
  refreshInterval: SECONDS_IN_A_DAY,
  appName: process.env.REACT_APP_UNLEASH_APP_NAME,
  environment: process.env.REACT_APP_UNLEASH_ENVIRONMENT
};
export const unleashClient = new UnleashClient(unleashConfig);
