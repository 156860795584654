"use strict";
export const accessibilityPhone = (maskedPhone) => {
  if (!maskedPhone) {
    return "";
  }
  let phone = maskedPhone.replaceAll(" ", "");
  const ddd = phone.substring(0, 2);
  phone = phone.replace(ddd, "").split("").join(" ");
  return `${ddd} ${phone}`;
};
