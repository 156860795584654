"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  createContext,
  useCallback,
  useContext,
  useMemo
} from "react";
import { useParams } from "react-router-dom";
import AuthModalContent from "./partials/AuthModalContent";
import AlertModalContent from "./partials/AlertModalContent";
import FeedbackModalContent from "./partials/FeedbackModalContent";
import DetailsModalContent from "./partials/DetailsModalContent";
import ClipboardModalContent from "./partials/ClipboardModalContent";
import useModal from "~/hooks/useModal";
import { forceDownloadPdf } from "~/utils/helpers";
import { xRay } from "~/utils/tracking";
import { setSessionItem, removeSessionItem, getSessionItem } from "~/utils/storage";
import { usePrescriptionContext } from "../PrescriptionContext";
const ModalsContext = createContext({});
export const ModalsProvider = ({ children }) => {
  const [AuthModal, { toggle: toggleAuth, isOpen: isOpenAuth }] = useModal();
  const [AlertModal, { toggle: toggleAlert, isOpen: isOpenAlert }] = useModal();
  const [FeedbackModal, { toggle: toggleFeedback, isOpen: isOpenFeedback }] = useModal();
  const [DetailsModal, { toggle: toggleDetails, isOpen: isOpenDetails }] = useModal();
  const [ClipboardModal, { toggle: toggleClipboard, isOpen: isOpenClipboard }] = useModal();
  const { token } = useParams();
  const {
    patient,
    prescription,
    updateTerms,
    unlockPrescription
  } = usePrescriptionContext();
  const updateTermsCallback = useCallback((code = "") => __async(void 0, null, function* () {
    const privacyForm = getSessionItem("privacy_form");
    if (privacyForm) {
      const response = yield updateTerms(privacyForm || patient.itens, code);
      if (response.itens && response.itens.length) {
        removeSessionItem("privacy_form");
        toggleAuth();
        setSessionItem(`prescription_unlock_code_${token}`, code);
      }
    }
  }), [patient.itens, toggleAuth, token, updateTerms]);
  const triggerPrescriptionDownload = useCallback((pdf, url) => {
    if (prescription.isSigned) {
      forceDownloadPdf(pdf, url);
    }
    toggleFeedback();
  }, [toggleFeedback, prescription]);
  const unlockPrescriptionCallback = useCallback((code) => __async(void 0, null, function* () {
    const isPrescriptionDetails = getSessionItem("prescription_details");
    const isDownloadPdf = getSessionItem("download_pdf");
    const sessionCode = getSessionItem(`prescription_unlock_code_${token}`);
    let { document } = prescription;
    let { digits } = prescription;
    if (code || sessionCode && (!document.pdf || !document.url)) {
      const response = yield unlockPrescription(code || sessionCode);
      if (response.data) {
        const { prescription: prescription2 } = response.data.attributes;
        document = prescription2.document;
        digits = prescription2.digits;
        setSessionItem(`prescription_unlock_code_${token}`, code || sessionCode);
        if (code) {
          toggleAuth();
        }
        if (isPrescriptionDetails && digits) {
          toggleDetails();
          return;
        }
        triggerPrescriptionDownload(document.pdf, document.url);
        return;
      }
    }
    if (isPrescriptionDetails && digits) {
      toggleDetails();
      return;
    }
    if (isDownloadPdf && sessionCode && (document.pdf || document.url)) {
      triggerPrescriptionDownload(document.pdf, document.url);
      return;
    }
    if (!isOpenAuth) {
      toggleAuth();
    }
  }), [prescription, toggleAuth, isOpenAuth, toggleDetails, token, unlockPrescription, triggerPrescriptionDownload]);
  const toggleAuthMethod = useCallback((..._0) => __async(void 0, [..._0], function* ({
    downloadPdf,
    prescriptionDetails
  } = {}) {
    if (downloadPdf) {
      setSessionItem("download_pdf", "true");
      const { items: { industrialized, manipulated, exams }, isSigned } = prescription;
      xRay.sendEvent({
        name: "Download PDF Click",
        screen: "assistant_home",
        properties: {
          digital_signature_status: !!isSigned,
          has_drugs: !!industrialized.data.length || !!manipulated.data.length,
          has_exams: !!exams.data.length
        }
      });
      removeSessionItem("prescription_details");
    } else if (prescriptionDetails) {
      setSessionItem("prescription_details", "true");
      removeSessionItem("download_pdf");
    } else {
      toggleAuth();
      return;
    }
    unlockPrescriptionCallback();
  }), [prescription, toggleAuth, unlockPrescriptionCallback]);
  const value = useMemo(() => ({
    toggleAuthMethod,
    toggleAuth,
    toggleAlert,
    toggleFeedback,
    toggleClipboard,
    updateTermsCallback,
    unlockPrescriptionCallback
  }), [
    toggleAuthMethod,
    toggleAuth,
    toggleAlert,
    toggleFeedback,
    toggleClipboard,
    updateTermsCallback,
    unlockPrescriptionCallback
  ]);
  return /* @__PURE__ */ jsxs(ModalsContext.Provider, { value, children: [
    children,
    /* @__PURE__ */ jsx(AuthModal, { direction: "right", isOpen: isOpenAuth, children: /* @__PURE__ */ jsx(AuthModalContent, {}) }),
    /* @__PURE__ */ jsx(AlertModal, { direction: "center", isOpen: isOpenAlert, children: /* @__PURE__ */ jsx(AlertModalContent, {}) }),
    /* @__PURE__ */ jsx(FeedbackModal, { direction: "center", isOpen: isOpenFeedback, children: /* @__PURE__ */ jsx(FeedbackModalContent, {}) }),
    /* @__PURE__ */ jsx(DetailsModal, { direction: "bottom", title: "Detalhes da receita", isOpen: isOpenDetails, children: /* @__PURE__ */ jsx(DetailsModalContent, {}) }),
    /* @__PURE__ */ jsx(ClipboardModal, { direction: "bottom", title: "C\xF3digo da receita", isOpen: isOpenClipboard, children: /* @__PURE__ */ jsx(ClipboardModalContent, {}) })
  ] });
};
export const useModalsContext = () => useContext(ModalsContext);
