"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Typography, Button } from "@patients-ui/components";
import { useModalContext } from "~/hooks/useModal";
import { usePrescriptionContext } from "../../../PrescriptionContext";
const FeedbackModalContent = () => {
  const { toggle } = useModalContext();
  const { prescription } = usePrescriptionContext();
  return /* @__PURE__ */ jsxs("div", { className: "p-6", children: [
    /* @__PURE__ */ jsxs("div", { className: "flex flex-col gap-4", children: [
      /* @__PURE__ */ jsx(Typography, { as: "h1", variant: "subtitle", spacing: "distant", weight: "500", children: prescription.isSigned ? "Baixando receita em PDF" : "Receita sem assinatura" }),
      /* @__PURE__ */ jsx(Typography, { variant: "label", spacing: "distant", weight: "400", children: prescription.isSigned ? "Sua receita est\xE1 sendo baixada e ser\xE1 exibida quando o download estiver completo" : "A receita n\xE3o foi assinada e n\xE3o pode ser baixada, entre em contato com o m\xE9dico" })
    ] }),
    /* @__PURE__ */ jsx(Button, { className: "mt-4", title: "Ok, entendi", size: "sm", onClick: toggle, children: "Ok, entendi" })
  ] });
};
export default FeedbackModalContent;
